<template>
  <div class="home">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64" />
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      overlay: true
    }
  }
}
</script>

<style scoped>
  .home {

  }
</style>
